<template>
  <div>
    <vxe-toolbar class="px-3" custom :refresh="{ query: getStockList }">
      <template v-slot:buttons>
        <vxe-input
          v-model="filterName"
          type="search"
          placeholder="全文搜索"
          class="mr-1"
        ></vxe-input>

        <UserFilter
          colName="focus_users"
          placeholder="关注人"
          :autoSave="true"
          :table="$refs.coreTable"
          inputWidth="100px"
          class="mr-1"
        ></UserFilter>
        <UserFilter
          colName="research_user"
          placeholder="研究员"
          :autoSave="true"
          :table="$refs.coreTable"
          inputWidth="100px"
          class="mr-1"
        ></UserFilter>
        <Shenwan3Filter
          colName="industry_name"
          placeholder="申万三级"
          :table="$refs.coreTable"
          inputWidth="100px"
          class="mr-1"
        ></Shenwan3Filter>
        <LevelFilter
          colName="level"
          placeholder="跟踪级别"
          :table="$refs.coreTable"
          inputWidth="100px"
          class="mr-1"
        >
        </LevelFilter>
        <RaceFilter
          colName="races"
          placeholder="赛道"
          :table="$refs.coreTable"
          inputWidth="100px"
          class="mr-1"
        >
        </RaceFilter>
        <!-- <MarketFilter
          colName="type"
          placeholder="市场"
          :table="$refs.coreTable"
          inputWidth="100px"
          class="mr-1"
        >
        </MarketFilter> -->

        <vxe-button
          @click="stockAdd()"
          size="medium"
          content="调入新股票"
          class="ml-0 mr-1"
          status="my-primary"
        >
        </vxe-button>
        <!-- <vxe-button @click="exportStockPool()" size="medium" content="导出" class="ml-0 mr-1" status="my-primary"></vxe-button> -->
        <vxe-button
          @click="multiDisplay()"
          size="medium"
          :content="showMulti ? '关闭批量' : '批量操作'"
          class="ml-0 mr-1"
          status="my-primary"
        ></vxe-button>
        <vxe-button
          @click="multiRemove()"
          size="medium"
          content="批量调出"
          v-if="showMulti"
          class="ml-0 mr-1"
          status="my-primary"
        ></vxe-button>
        <vxe-button
          v-if="$commonHelpers.getPermissions('stockPool.export')"
          @click="exportExcel()"
          size="medium"
          content="导出"
          class="ml-0 mr-1"
          status="my-primary"
        ></vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      id="coreTable"
      row-id="id"
      ref="coreTable"
      :data="pageData"
      :border="false"
      resizable
      show-overflow
      show-header-overflow
      highlight-hover-row
      align="center"
      :loading="coreTableLoading"
      :column-config="{ resizable: true }"
      :height="tableHeight"
      size="small"
      :stripe="true"
      :sort-config="{ trigger: 'cell', multiple: false }"
      @sort-change="sortChangeEvent"
      :empty-render="{ name: 'NotData' }"
      @cell-mouseenter="stockEnter"
      @cell-mouseleave="stockLeave"
      :custom-config="{ storage: false, checkMethod: checkColumnMethod }"
      :tooltip-config="{ showAll: false, enterable: true, theme: 'dark' }"
      :checkbox-config="{ checkMethod: checkDisplayController, range: true }"
      :export-config="{}"
      :filter-config="{
        remote: true,
        showIcon: false,
      }"
    >
      <vxe-column
        field="multi"
        fixed="left"
        type="checkbox"
        title="批量"
        min-width="60"
      ></vxe-column>

      <vxe-column
        fixed="left"
        type="seq"
        width="60"
        title="序号"
        :visible="false"
      ></vxe-column>

      <vxe-column
        title="评分"
        fixed="left"
        type="seq"
        min-width="50"
        :resizable="false"
        show-overflow
      >
        <template #default="{ row }">
          <a
            @click="openRate(row)"
            class="text-xs font-weight-normal text-typo"
          >
            <v-icon small>mdi-launch</v-icon>
          </a>
        </template>
      </vxe-column>

      <vxe-column
        title="修正"
        fixed="left"
        type="seq"
        min-width="68"
        :resizable="false"
        show-overflow
      >
        <template #default="{ row }">
          <div v-if="row.is_trans_out == 0">
            <a
              href="javascript:;"
              @click="stockRemove(row.id)"
              class="text-xs font-weight-normal text-typo"
              >调出</a
            >
            <a
              href="javascript:;"
              @click="stockEdit(row.id)"
              class="text-xs font-weight-normal text-typo"
              style="margin-left: 5px"
              >修改</a
            >
          </div>
          <div v-else>申请调出中</div>
        </template>
      </vxe-column>

      <vxe-column
        fixed="left"
        min-width="70"
        field="stock_code"
        title="股票代码"
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <a
              href="javascript:;"
              @click="$stockHelpers.openStockDetail(row.stock_id)"
              class="text-xs font-weight-normal text-typo"
              >{{ row.stock_code ? row.stock_code : "-" }}</a
            >
          </div>
        </template>
      </vxe-column>

      <vxe-column
        fixed="left"
        min-width="80"
        field="stock_name"
        title="股票名称"
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <a
              href="javascript:;"
              @click="$stockHelpers.openStockDetail(row.stock_id)"
              class="text-xs font-weight-normal text-typo"
              >{{ row.stock_name ? row.stock_name : "-" }}</a
            >
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="60"
        field="level"
        title="跟踪级别"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'level')
        "
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.level }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="100"
        field="industry_name"
        title="申万三级"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'industry_name')
        "
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div
              style="
                text-align: start;
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              {{ row.industry_name }}
            </div>
          </div>
        </template>
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              style="margin: 10px; width: 140px; height: 32px"
              class="border px-3"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
      </vxe-column>
      <vxe-column
        min-width="100"
        field="sw_level1_name"
        title="申万一级"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'sw_level1_name')
        "
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div
              style="
                text-align: start;
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              {{ row.sw_level1_name }}
            </div>
          </div>
        </template>
        <template #filter="{ $panel, column }">
          <template v-for="(option, index1) in column.filters">
            <input
              style="margin: 10px; width: 140px; height: 32px"
              class="border px-3"
              type="type"
              :key="index1"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
      </vxe-column>
      <!-- <vxe-column
        :visible="false"
        min-width="80"
        field="type"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'market')
        "
        title="市场"
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              style="margin: 10px; width: 140px; height: 32px"
              class="border px-3"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
      </vxe-column> -->

      <vxe-column min-width="120" field="styles" title="风格">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div class="d-flex" style="flex-wrap: wrap">
              <div
                v-for="(race, index) in row.races"
                v-if="race.level === 0"
                :key="race.id"
                class="px-1 py-0"
                style="
                  cursor: pointer;
                  font-size: 10px;
                  color: #fff;
                  border-radius: 3px;
                  margin-bottom: 1px;
                  margin-right: 1px;
                "
                :style="{
                  backgroundColor:
                    race.color == `#e8f5e9` ? `#00b294` : race.color,
                }"
                @click="$stockHelpers.openTagDetail(race.id)"
              >
                {{ race.name }}
              </div>
            </div>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="220"
        field="races"
        title="赛道"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'races')
        "
      >
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div class="d-flex" style="flex-wrap: wrap">
              <span
                v-for="(race, index) in row.races"
                v-if="race.level !== 0"
                :key="race.id"
                class="px-1 py-0"
                style="
                  cursor: pointer;
                  font-size: 10px;
                  color: #fff;
                  border-radius: 3px;
                  margin-bottom: 1px;
                  margin-right: 1px;
                "
                :style="{
                  backgroundColor:
                    race.color == `#e8f5e9` ? `#00b294` : race.color,
                }"
                @click="$stockHelpers.openTagDetail(race.id)"
              >
                {{ race.name }}
              </span>
            </div>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="80" field="updated_at" title="更新日期" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <span :style="$stockHelpers.getDateColor(row.updated_at)">{{
              row.updated_at
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="65" field="fundamentals" title="基本面" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.fundamentals ? row.fundamentals : "-" }}
          </div>
        </template>
      </vxe-column>
      <vxe-column min-width="65" field="catalyst" title="催化剂" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.catalyst ? row.catalyst : "-" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="65" field="volatility" title="波动率" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.volatility ? row.volatility : "-" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="70"
        field="market_value_million"
        title="市值/亿"
        sortable
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.market_value_million ? row.market_value_million : "-" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="80"
        field="current_market_million"
        title="流通/亿"
        sortable
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.current_market_million ? row.current_market_million : "-" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="65" field="close_price" title="股价" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.close_price ? row.close_price : "-" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="80" field="applies_day" title="当日涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.applies_day)">{{
              $stockHelpers.getPercent(row.applies_day)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="65" field="volume_ratio" title="量比" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span>{{ row.volume_ratio }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="80" field="pe_ttm" title="PE-TTM" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.pe_ttm ? row.pe_ttm : "-" }}
          </div>
        </template>
      </vxe-column>

      <!-- <vxe-column min-width="70" field="roe" title="ROE" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
			</vxe-column> -->

      <vxe-column min-width="70" field="peg" title="PEG" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.peg ? row.peg : "-" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="50" field="pb" title="PB" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.pb ? row.pb : "-" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="100"
        :title="'PE(' + pesArr[0] + ')'"
        field="pes[0]"
        sortable
      >
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{
              row.pes
                ? row.pes["fy" + pesArr[0]]
                  ? parseFloat(row.pes["fy" + pesArr[0]]).toFixed(2)
                  : "数据未出"
                : "-"
            }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="100"
        :title="'PE(' + pesArr[1] + ')'"
        field="pes[1]"
        sortable
      >
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{
              row.pes
                ? row.pes["fy" + pesArr[1]]
                  ? parseFloat(row.pes["fy" + pesArr[1]]).toFixed(2)
                  : "数据未出"
                : "-"
            }}
          </div>
        </template>
      </vxe-column>
      <vxe-column
        min-width="100"
        :title="'PE(' + pesArr[2] + ')'"
        field="pes[2]"
        sortable
      >
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{
              row.pes
                ? row.pes["fy" + pesArr[2]]
                  ? parseFloat(row.pes["fy" + pesArr[2]]).toFixed(2)
                  : "数据未出"
                : "-"
            }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="80" field="applies_five" title="5日涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.applies_five)">{{
              $stockHelpers.getPercent(row.applies_five)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="80" field="applies_20" title="20日涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.applies_20)">{{
              $stockHelpers.getPercent(row.applies_20)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="80" field="applies_60" title="60日涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.applies_60)">{{
              $stockHelpers.getPercent(row.applies_60)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="80"
        field="applies_focus"
        title="入池涨幅"
        sortable
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.applies_focus)">{{
              $stockHelpers.getPercent(row.applies_focus)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="80" field="created_at" title="入池日期" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.created_at }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        v-if="$commonHelpers.getPermissions('stockPool.showFocusUsers')"
        fixed="right"
        min-width="100"
        field="focus_users"
        title="关注人"
        sortable
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'focus_users')
        "
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              class="pa-2"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div
              style="
                word-break: keep-all;
                white-space: pre-line;
                text-align: start;
              "
            >
              {{ row.focus_users }}
            </div>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        fixed="right"
        min-width="100"
        field="research_user"
        title="研究员"
        sortable
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'research_user')
        "
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              class="pa-2"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div
              style="
                word-break: keep-all;
                white-space: pre-line;
                text-align: start;
              "
            >
              {{ row.research_user }}
            </div>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        fixed="right"
        min-width="100"
        field="update_user"
        title="更新人"
        sortable
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'update_user')
        "
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              class="pa-2"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div
              style="
                word-break: keep-all;
                white-space: pre-line;
                text-align: start;
              "
            >
              {{ row.update_user }}
            </div>
          </div>
        </template>
      </vxe-column>
    </vxe-table>

    <vxe-pager
      :current-page="pageInfo.currentPage"
      :page-size.sync="pageInfo.pageSize"
      :total="this.tableData.length"
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Total', 'Sizes']"
      @sort-change="sortChangeEvent"
      @page-change="
        (value) =>
          getPage(value, this.tableData, [$refs['coreTable']], pageInfo)
      "
    >
    </vxe-pager>

    <hoverBox ref="stockHover">
      <template slot="content">
        <div
          class="px-3 pt-3"
          style="
            width: 500px;
            height: 100%;
            font-size: 13px;
            white-space: pre-line;
          "
        >
          <span style="font-weight: bolder">入池理由:</span> {{ poolReason }}
        </div>
        <div
          class="px-3 pb-3"
          style="
            width: 500px;
            height: 100%;
            font-size: 13px;
            white-space: pre-line;
          "
        >
          <span style="font-weight: bolder">公司简介:</span> {{ intro }}
        </div>
      </template>
    </hoverBox>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import StockPool from "@/api/stockPool.js";
import Stock from "@/api/stock.js";
import hoverBox from "@/components/HoverBox";
import XEUtils from "xe-utils";
import StockAdd from "@/components/StockPool/StockAdd.vue";
import StockEdit from "@/components/StockPool/StockEdit.vue";
import User from "@/api/user.js";

import UserFilter from "@/components/StockPool/filter/UserFilter";
import Shenwan3Filter from "@/components/StockPool/filter/Shenwan3Filter";
import LevelFilter from "@/components/StockPool/filter/LevelFilter";
import RaceFilter from "@/components/StockPool/filter/RaceFilter";
import MarketFilter from "@/components/StockPool/filter/MarketFilter";

export default {
  name: "StockPool",
  props: {},
  data() {
    return {
      firstLoad: true,
      stockList: [],
      coreTableLoading: false,

      tableHeight: "500px",
      stockLevelMap: {
        1: "核心",
        2: "备选",
        3: "一级",
        4: "二级",
        5: "三级",
      },

      filterName: "",
      tableData: [],
      cacheData: [],

      pageInfo: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
      },
      sortInfo: {
        field: "updated_at",
        order: "desc",
      },
      filters: [],
      pageData: [],

      showMulti: false,
      poolReason: "",
      intro: "",
      pesArr: [],

      refreshTimer: null,
    };
  },
  components: {
    hoverBox,
    StockAdd,
    StockEdit,

    UserFilter,
    Shenwan3Filter,
    LevelFilter,
    RaceFilter,
    MarketFilter,
  },
  mounted() {
    this.tableHeight = document.body.offsetHeight - 180 + "px";
    this.getStockList();

    Bus.$on("refreshCoreStockPool", (e) => {
      this.getStockList();
    });

    Bus.$on("updateFilter", (e) => {
      if (e.table.$options.propsData.id == "coreTable") {
        if (this.cacheData.length != 0) {
          this.filterName = "";
          this.filterChange(e);
        } else {
          let timer = setInterval(() => {
            if (this.cacheData.length != 0) {
              this.filterName = "";
              this.filterChange(e);
              clearInterval(timer);
            }
          }, 500);
        }
      }
    });
  },
  beforeDestroy() {
    Bus.$off("refreshCoreStockPool");
    Bus.$off("updateFilter");
  },
  watch: {
    filterName(val) {
      const filterName = XEUtils.toValueString(val).trim().toLowerCase();
      const searchProps = [
        "stock_name",
        "stock_code",
        "level",
        "pinyin",
        "industry_name",
        "industry_pinyin",
        "styleGroupString",
        "stylePYString",
        "racesGroupString",
        "racesPYString",
        "focus_users",
        "research_user",
        "update_user",
      ];
      const rest = this.cacheData.filter((item) =>
        searchProps.some(
          (key) =>
            XEUtils.toValueString(item[key]).toLowerCase().indexOf(filterName) >
            -1
        )
      );
      this.tableData = rest;
      this.$refs.coreTable.clearSort();

      if (val) {
        this.pageInfo.totalResult = rest.length;
      } else {
        this.tableData = this.cacheData;
        this.pageInfo.totalResult = this.tableData.length;
      }

      this.pageData = this.$tableHelpers.getPageWithSort(
        this.pageInfo,
        rest,
        [this.$refs.coreTable],
        this.sortInfo
      );
    },
  },
  methods: {
    filterChange(filter) {
      this.sortInfo = {
        field: "updated_at",
        order: "desc",
      };

      this.tableData = this.cacheData;

      let allFilterIsEmpty = true;
      let haveThisFilter = false;
      this.filters.forEach((e) => {
        if (e.colName == filter.colName) {
          haveThisFilter = true;
          e.data = filter.data;
        }
      });
      //更新筛选器数组
      if (!haveThisFilter) {
        this.filters.push(filter);
      }

      this.filters.forEach((e) => {
        if (e.data) {
          allFilterIsEmpty = false;
          //如果当前列的筛选器不为空，则进行筛选
          this.tableData = this.tableData.filter((item) => {
            if (Array.isArray(item[e.colName])) {
              let str = JSON.stringify(item[e.colName]);
              return str.indexOf(e.data) > -1;
            } else {
              return item[e.colName].indexOf(e.data) > -1;
            }
          });
        }
      });

      //使用vue的set更新分页数据
      this.$set(this.pageInfo, "currentPage", 1);
      this.$set(this.pageInfo, "totalResult", this.tableData.length);

      this.pageData = this.$tableHelpers.getPageWithSort(
        this.pageInfo,
        this.tableData,
        [this.$refs.coreTable],
        this.sortInfo
      );

      this.$refs.coreTable.reloadData(this.pageData);

      if (allFilterIsEmpty) {
        this.tableData = this.cacheData;
        this.pageData = this.$tableHelpers.getPageWithSort(
          this.pageInfo,
          this.tableData,
          [this.$refs.coreTable],
          this.sortInfo
        );
        return;
      }
    },
    getPage(pager, tableData, table, pageObj) {
      pageObj.currentPage = pager.currentPage;
      this.pageData = this.$tableHelpers.getPageWithSort(
        pager,
        tableData,
        table,
        this.sortInfo
      );
    },
    sortChangeEvent({ sortList }) {
      //console.log(sortList);
      this.sortInfo = sortList[0];

      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      const searchProps = ["updated_at"];
      const rest = this.tableData.filter((item) =>
        searchProps.some(
          (key) =>
            XEUtils.toValueString(item[key]).toLowerCase().indexOf(filterName) >
            -1
        )
      );

      this.pageData = this.$tableHelpers.getPageWithSort(
        this.pageInfo,
        rest,
        [this.$refs.coreTable],
        this.sortInfo
      );
    },
    filterChangeEvent({ column, property, values, datas, filterList, $event }) {
      console.log(column, property, values, datas, filterList, $event);
    },
    filterMethod({ options, values, cellValue, row, column }) {
      console.log(options, values, cellValue, row, column);
    },

    //显示列控制
    checkColumnMethod({ column }) {
      if (column.property === "role") {
        return false;
      }
      return true;
    },
    //获取股票列表
    getStockList() {
      this.coreTableLoading = true;
      StockPool.getStockPoolList(1, 1)
        .then((res) => {
          res.data.forEach((e) => {
            //处理赛道
            e.styleGroupString = "";
            e.stylePYString = "";
            if (e.styles) {
              e.styles.forEach((a) => {
                e.styleGroupString = e.styleGroupString =
                  e.styleGroupString + a.name + ",";
                e.stylePYString = e.stylePYString =
                  e.stylePYString + a.pinyin + ",";
              });
            }

            e.racesGroupString = "";
            e.racesPYString = "";
            if (e.races) {
              e.races.forEach((a) => {
                e.racesGroupString = e.racesGroupString =
                  e.racesGroupString + a.name + ",";
                e.racesPYString = e.racesPYString =
                  e.racesPYString + a.pinyin + ",";
              });
            }
            //处理量化
            for (let i in e.quant_amount) {
              e["quant_amount_" + i] = e.quant_amount[i];
            }
          });

          if (res.data.length > 0) {
            this.pesArr = [];
            let maxBenchmark = 0;
            res.data.forEach((e) => {
              //获取pes中bench字段最大的值
              if (e.pes) {
                if (maxBenchmark === 0) {
                  maxBenchmark = e.pes.bench;
                } else {
                  if (e.pes.bench > maxBenchmark) {
                    maxBenchmark = e.pes.bench;
                  }
                }
                e.pes["fy" + e.pes.bench] = e.pes.fy1;
                e.pes["fy" + (parseInt(e.pes.bench) + 1)] = e.pes.fy2;
                e.pes["fy" + (parseInt(e.pes.bench) + 2)] = e.pes.fy3;
              }
            });
            this.pesArr = [
              parseInt(maxBenchmark),
              parseInt(maxBenchmark) + 1,
              parseInt(maxBenchmark) + 2,
            ];
          }
          console.log(res.data);
          this.tableData = res.data;
          this.cacheData = res.data;
          //初始化分页
          this.pageInfo.totalResult = this.tableData;
          this.pageData = this.$tableHelpers.getPageWithSort(
            this.pageInfo,
            this.tableData,
            [this.$refs.coreTable],
            this.sortInfo
          );

          if (this.firstLoad) {
            this.$refs.coreTable.hideColumn(
              this.$refs.coreTable.getColumnByField("multi")
            );
            this.firstLoad = false;
          }

          clearInterval(this.refreshTimer);
          this.refreshStock();
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.coreTableLoading = false;
        });
    },
    refreshStock() {
      let refreshMethod = () => {
        let stockList = [];
        this.tableData.forEach((stock) => {
          stockList.push(stock.stock_code);
        });
        Stock.getRealtimeData(stockList)
          .then((res) => {
            //修改股票实时数据
            this.tableData.forEach((stock, index) => {
              let stockData = res.data.filter(
                (item) => item.code === stock.stock_code
              );
              if (stockData.length > 0) {
                if (stockData[0].price !== 0) {
                  this.tableData[index].close_price = stockData[0].price;
                  this.tableData[index].applies_day = stockData[0].applies;
                }
              }
            });
          })
          .catch((err) => {
            console.log(err);
            this.showToast(err.msg, "danger", 10000);
          });
      };

      refreshMethod();
      this.refreshTimer = setInterval(() => {
        refreshMethod();
      }, 60000);
    },
    //打开打分
    openRate(row) {
      this.$stockHelpers.openRate(row.stock_id, row.stock_name, this);
    },
    //显示弹窗
    stockEnter({
      row,
      rowIndex,
      $rowIndex,
      column,
      columnIndex,
      $columnIndex,
      $event,
    }) {
      if (column.field == "stock_name" || column.field == "stock_code") {
        this.poolReason = row.reason ? row.reason : "暂无入池说明";
        this.intro = row.intro ? row.intro : "暂无公司简介";
        this.$refs.stockHover.show($event.clientX, $event.clientY, "", "", ``);
      }
    },
    //关闭弹窗
    stockLeave() {
      this.$refs.stockHover.hide();
    },
    //调入股票
    stockAdd() {
      this.$layer.iframe({
        content: {
          content: StockAdd,
          parent: this,
          data: { iframeData: { isOtc: false } },
        },
        area: ["600px", "650px"],
        title: "调入新股票(带*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    //修改股票
    stockEdit(id) {
      this.showLoading("获取股票已有信息中，请稍候");
      StockPool.getStockDetail(id)
        .then((res) => {
          console.log(res.data);
          res.data.isOtc = false;
          this.$layer.iframe({
            content: {
              content: StockEdit,
              parent: this,
              data: { iframeData: res.data },
            },
            area: ["600px", "600px"],
            title: "修改股票(带*为必填)--" + res.data.stock.name,
            maxmin: false,
            shade: true,
            shadeClose: false,
            cancel: () => {
              //关闭弹窗事件
            },
          });
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    //调出
    stockRemove(id) {
      this.$layer.confirm("确定要申请调出吗？", (layerid) => {
        this.showLoading("申请调出中，请稍候");
        StockPool.removeStock(id, 1)
          .then((res) => {
            this.showToast("已成功提交调出申请！", "success", 5000);
            Bus.$emit("refreshCoreStockPool", true);
            Bus.$emit("refreshApplyStockPool", true);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
    //展示批量操作
    multiDisplay() {
      this.showMulti = !this.showMulti;
      if (this.showMulti) {
        this.$refs.coreTable.showColumn(
          this.$refs.coreTable.getColumnByField("multi")
        );
      } else {
        this.$refs.coreTable.hideColumn(
          this.$refs.coreTable.getColumnByField("multi")
        );
      }
    },
    //批量调出
    multiRemove() {
      let selectRecords = this.$refs.coreTable.getCheckboxRecords();
      if (selectRecords.length < 1) {
        this.showToast("请先选择项目", "warning", 5000);
        return;
      }
      let arr = [];
      selectRecords.forEach((i) => {
        arr.push(i.id);
      });
      console.log(arr);
      this.$layer.confirm("确定要批量调出吗？", (layerid) => {
        this.showLoading("批量调出中，请稍候");
        StockPool.multiRemoveStock(arr)
          .then((res) => {
            this.showToast("已完成批量调出！", "success", 5000);
            Bus.$emit("refreshCoreStockPool", true);
            Bus.$emit("refreshApplyStockPool", true);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
    checkDisplayController({ row }) {
      return row.is_trans_out == 0;
    },

    //关注人筛选
    filterFocusUser(data) {
      const coreTable = this.$refs.coreTable;
      const column = coreTable.getColumnByField("focus_users");
      const option = column.filters[0];
      option.data = data;
      option.checked = true;
      coreTable.updateData();
    },

    exportExcel() {
      this.showLoading("导出中请稍候");
      StockPool.exportExcel()
        .then((res) => {
          window.location = res.data.url;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
